import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, Collapse, Typography, Link} from "@mui/material";
import DownloadWordPressPluginButton from "../DownloadWordPressPluginButton";
import {LoadingButton} from "@mui/lab";
import {useGetReportsQuery} from "../../store/slice/myContentDashAdminApi";

type SetupReportOwnerProps = {
    children?: string;
    onReportConnected?: () => void;
}

const SetupReportOwner = ({children, onReportConnected}: SetupReportOwnerProps) => {
    const [error, setError] = useState<null | string>(null);
    const [hasPluginBeenDownloaded, setHasPluginBeenDownloaded] = useState(false);
    const {data: reportsList, isFetching, refetch} = useGetReportsQuery();
    const [previousReportsLength] = useState(reportsList.reports.length);
    const [hasChecked, setHasChecked] = useState(false);

    const hasNewReportBeenFound = reportsList.reports.length !== previousReportsLength;

    const checkStatus = () => {
        refetch();
        setHasChecked(true);
    }

    useEffect(() => {
        if(previousReportsLength !== reportsList.reports.length && Boolean(onReportConnected)) {
            onReportConnected();
        }
    }, [onReportConnected, previousReportsLength, reportsList.reports.length]);

    return <>
        <Typography>{children ? children : "Your dashboard is almost ready. The last step is connecting your WordPress website to MyContentDash."}</Typography>
        <ol>
            <li><Typography>Download the MyContentDash companion plugin.</Typography></li>
            <li><Typography>Upload and activate the plugin.</Typography></li>
            <li><Typography>Hover over the "Tools" menu of your WordPress admin sidebar,
                then select "MyContentDash" from the menu.</Typography></li>
            <li><Typography>Follow the instructions in the wizard.</Typography></li>
            <li><Typography>Once connected, use the button below to check the status of your
                report.</Typography></li>
        </ol>
        <Typography align='center'>If you run into any issues, please email <Link href="mailto:hello@mycontentdash.com">hello@mycontentdash.com</Link></Typography>
        <Collapse in={hasChecked && !hasNewReportBeenFound && !isFetching}>
            <Typography color={'error'} sx={{mt: 1}}>We weren't able to find the connection to your website, you may need to try again or contact our support team.</Typography>
        </Collapse>
        <Collapse in={error !== null}>
            <Typography color={'error'} sx={{mt: 1}}>{error}</Typography>
        </Collapse>
        <Box display={'flex'} justifyContent={'center'} alignItems={"center"} mt={2}>
            <DownloadWordPressPluginButton
                onDownloaded={() => setHasPluginBeenDownloaded(true)}
                onError={error => setError(error)}
                sx={{mr: hasPluginBeenDownloaded ? 1 : 0}}
                variant={hasPluginBeenDownloaded ? 'text' : 'contained'}
                fullWidth={hasPluginBeenDownloaded}
            >
                {hasPluginBeenDownloaded ? "Download" : "Download WordPress Plugin"}
            </DownloadWordPressPluginButton>
            {
                hasPluginBeenDownloaded ? (
                    <LoadingButton
                        sx={{ml: 1}}
                        fullWidth
                        variant={'contained'}
                        onClick={checkStatus}
                        loading={isFetching}
                    >Check Status</LoadingButton>
                ) : null
            }
        </Box>
    </>
}

export default SetupReportOwner;