import React, {useContext, useEffect, useState} from "react";
import {FeaturedFilter} from "../../table/useTableFilters";
import {useGetNotesQuery} from "../../../store/slice/myContentDashAdminApi";
import ReportContext from "../../../context/ReportContext";
import {Box, Button, Divider, FormControlLabel, IconButton, TextField, Radio, RadioGroup} from "@mui/material";
import {Search} from "@mui/icons-material";
import fieldFeaturedFilter from "./FieldFeaturedFilter";

export type PageNotesFilterValue = {
    exists?: undefined | true | false,
    contains?: string
} | null;

export type PageNotesFilterProps = {
    value: PageNotesFilterValue,
    onChange: (newValue: PageNotesFilterValue) => void;
    featuredFilter: FeaturedFilter;
    closePopover: () => void
}

const checkmarkValues = [true, false, undefined];
const PageNotesFilter = ({value, onChange, featuredFilter}: PageNotesFilterProps) => {
    const [contains, setContains] = useState(value.contains);
    useEffect(() => {
        setContains(value.contains);
    }, [value]);
    const {report} = useContext(ReportContext);
    const {isLoading} = useGetNotesQuery({reportId: report.id});

    const applySearchValue = () => {
        onChange({
            exists: undefined,
            contains: contains === "" ? undefined : contains
        })
    }
    const valueToSelectedRadion = (valueToConvert) => {
        switch (valueToConvert) {
            case true:
                return "true";
            case false:
                return "false";
            case undefined:
                return "all"
            default:
                return "all"
        }
    }
    const onChangeOfRaioButton = (event) => {
        var newExists = undefined;
        if(event.target.value === "true") {
            newExists = true;
        }
        if(event.target.value === "false") {
            newExists = false;
        }
        onChange({
            ...value,
            exists: newExists
        });
    }

    return <Box width={"300px"} pb={2}>
            <RadioGroup
                value={valueToSelectedRadion(value.exists)}
                name="page-notes-radio"
                onChange={onChangeOfRaioButton}
            >
                <FormControlLabel value="true" control={<Radio />} label="Only With Page Notes" />
                <FormControlLabel value="false" control={<Radio />} label="Only Without Page Notes" />
                <FormControlLabel value="all" control={<Radio />} label="All Pages" />
            </RadioGroup>
        <Divider textAlign={"center"} sx={{my: 1}}>OR</Divider>
        <Box display={"flex"} alignItems={"center"}>
            <TextField
                label={"Page note contains..."}
                size={"small"}
                sx={{mt: 1, flex: 1}}
                value={contains}
                onChange={(event) => setContains(event.target.value)}
            />
            <IconButton sx={{mt: 1}} disabled={isLoading} onClick={applySearchValue}>
                <Search/>
            </IconButton>
        </Box>
        <Button
            variant={"text"}
            sx={{mt: 1}}
            onClick={() => onChange(fieldFeaturedFilter.getDefaultValue(featuredFilter) as PageNotesFilterValue)}
        >Reset</Button>
    </Box>;
}

export default PageNotesFilter;