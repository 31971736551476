import React, {useEffect, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import NullValue from "../NullValue";
import {format, fromUnixTime, addMinutes} from "date-fns";
import {makeStyles} from '@mui/styles'
import Tooltip from "@mui/material/Tooltip";
import {Link, TableCell} from "@mui/material";
import formatNumber from "../../util/formatNumber";
import {OpenInNew} from "@mui/icons-material";
import Column from "../../types/Column";
import {SelectedColumnFieldOptions} from "./ReportTable";

const useStyles = makeStyles(theme => ({
    root: {
        boxSizing: "border-box",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        margin: theme.spacing(1),
        paddingLeft: "0",
        paddingRight: "0",
        marginLeft: "0",
        marginRight: "0",
        flexBasis: "0",
        "& > .MuiTypography-root": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            overflow: "hidden"
        }
    }
}))

type ReportTableCellProps = {
    row: object;
    column: Column;
    fields: any;
    selectedColumnFieldOptions: SelectedColumnFieldOptions;
}

const ReportTableCell = ({row, column, fields, selectedColumnFieldOptions}: ReportTableCellProps) => {
    const classes = useStyles();
    const [isTruncated, setIsTruncated] = useState(false);
    const ref = useRef<HTMLSpanElement>();
    useEffect(() => {
        if (!ref.current) {
            return;
        }
        const truncated = ref.current.offsetWidth < ref.current.scrollWidth;
        setIsTruncated(truncated);
    }, []);

    const field = fields.find(field => field.id === column.field);

    const key = "field_" + column.field;

    let value;
    if (row[key] !== undefined && row[key] !== "") {
        if (column.use_multiple_fields) {
            const currentFieldId = selectedColumnFieldOptions[column.id];
            value = row["field_" + currentFieldId];
        } else {
            value = row[key];
        }

        if (field.type === 'float' && (column.formatting_options.format_number === undefined || column.formatting_options.format_number === true)) {
            value = formatNumber(value, 2);
        }

        if (field.type === "date") {
            try {
                const date = fromUnixTime(parseInt(row[key]));
                value = format(addMinutes(date, date.getTimezoneOffset()), "LLL d, yyyy");
            } catch (error) {
                value = <NullValue/>
            }
        }

        let originalValue = value;

        if (column.formatting_options.regex_replaces) {
            column.formatting_options.regex_replaces.forEach(regexReplaceDefinition => {
                let regExp = new RegExp(regexReplaceDefinition.regex, "i");

                if (value === null || value === undefined) {
                    value = '';
                } else {
                    value = value.toString();
                }

                value = value.replace(regExp, regexReplaceDefinition.replace);
            });
        }

        if (column.formatting_options.is_hyperlinked) {
            const originalURL = value;
            let urlLocation = originalValue;
            let anchorText;
            if (column.formatting_options.hyperlink_text)
                anchorText = column.formatting_options.hyperlink_text;
            else if (column.formatting_options.is_hyperlink_icon) {
                anchorText = <OpenInNew style={{fontSize: 20}} className={"valign-icon"}/>
            } else
                anchorText = column.formatting_options.hyperlink_text ? column.formatting_options.hyperlink_text : value;
            if (column.hyperlink_field !== null) {
                urlLocation = row["field_" + column.hyperlink_field]
            }

            value = <Link
                href={urlLocation}
                target={'_blank'}
                rel={"noopener"}
                title={"Open in new window"}
                color={"#1A283A"}
            >{anchorText}</Link>;

            if (column.formatting_options.is_hyperlink_icon) {
                value = <>{value} {originalURL}</>;
            }
        }

        value = <Typography ref={ref}>{value}</Typography>;

    } else {
        value = <NullValue/>;
    }

    return <TableCell>
        <div className={classes.root}>
            {isTruncated ? <Tooltip
                title={column.use_multiple_fields ? row["field_" + selectedColumnFieldOptions[column.id]] : row[key]}>
                {value}
            </Tooltip> : value}
        </div>
    </TableCell>;
}

export default ReportTableCell;